import gsap from 'gsap';
import { useEffect } from 'react';
import Footer from '../Footer/Footer';
import Form from '../Form/Form';
import './style.scss';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ServicesPages = () => {
  useEffect(() => {
    gsap.to('.hero-img__descktop', {
      duration: 1.3,
      clipPath: 'inset(0% 0 0% 0)',
      scale: 1,
      ease: 'power2.inOut',
    });
    gsap.to('h1 span', {
      duration: 1.3,
      delay: 0.2,
      y: 0,
      ease: 'power4.inOut',
    });

    gsap.utils.toArray('h2 span').forEach((span) => {
      gsap.to(span, {
        scrollTrigger: {
          trigger: span,
          start: '-700% 30%',
          end: '0 0',
        },
        duration: 1.3,
        y: 0,
        ease: 'power4.inOut',
      });
    });

    gsap.utils.toArray('.info-block__img img').forEach((img) => {
      gsap.to(img, {
        scrollTrigger: {
          trigger: img,
          start: '-50% 30%',
          end: '0 0',
        },
        duration: 1.3,
        clipPath: 'inset(0% 0 0% 0)',
        scale: 1,
        ease: 'power2.inOut',
      });
    });

    gsap.utils.toArray('.info-block__descr').forEach((img) => {
      gsap.to(img, {
        scrollTrigger: {
          trigger: img,
          start: '-50% 30%',
          end: '0 0',
        },
        duration: 1.3,
        opacity: 1,
        ease: 'power2.inOut',
      });
    });
  }, []);
  return (
    <>
      <section className='services-page hero'>
        <div className='hero-img'>
          <img
            className='hero-img__descktop'
            src='./img/info/bg.jpg'
            alt=''
          />
          {/* <img
            className='hero-img__mobile hero-img'
            src='./img/hero/hero-img.webp'
            alt=''
          /> */}
        </div>
        <div className='container'>
          <div className='hero__wrapper'>
            {/* <div className='services-page-img bottom-line'>
              <img
                src='./img/guarantee/logo.webp'
                alt=''
              />
            </div> */}
            <h1 className='hero-title'>
              <span>Zahnarzt Düsseldorf: Unsere Leistungen</span>
            </h1>
          </div>
        </div>
      </section>

      <section className='info'>
        <div className='info__wrapper'>
          <div className='info-block'>
            <div className='info-block__img'>
              <img
                src='./img/info/img1.webp'
                alt=''
              />
            </div>
            <div className='info-block__descr'>
              <h2>Implantate</h2>
              <h3>Was sind Zahnimplantate?</h3>
              <p>
                Das Einpflanzen von Implantaten stellt heute eine anerkannte und
                dauerhafte zahnmedizinische Behandlungsform dar. Implantate sind
                künstliche biokompatibele, d.h. gewebefreundliche Zahnwurzeln
                aus Titan oder Keramik. Diese werden ambulant chirurgisch in
                örtlicher Betäubung, wahlweise auch in Teilnarkose / Narkose, in
                den Kieferknochen eingesetzt.
              </p>
            </div>
          </div>
          <div className='info-block'>
            <div className='info-block__img'>
              <img
                src='./img/info/img2.webp'
                alt=''
              />
            </div>

            <div className='info-block__descr'>
              <h2>Zahnersatz</h2>
              <h3>Zahnersatz mit Kronen und Brücken.</h3>
              <p>
                Stark beschädigte Zähne werden mit einer Krone versorgt. Ein
                ästhetisch einwandfreies Ergebnis ermöglichen Keramikkronen. Sie
                sehen wie eigene Zähne aus und werden der Umgebung individuell
                angepasst. Sollte einmal ein Zahn verloren gehen, so besteht
                manchmal die ästhetische, immer aber die Funktion erhaltende
                Forderung nach vollwertigem Ersatz. Sind die Nachbarzähne ohne
                größere Füllungen, bietet sich eine implantatgetragene Lösung
                an. Sind jedoch die benachbarten Zähne ohnehin
                versorgungsbedürftig bzw. gibt es andere Gründe, die eine
                Implantation verhindern, so ist die festsitzende Brücke die
                richtige Entscheidung.
              </p>
            </div>
          </div>
          <div className='info-block'>
            <div className='info-block__img'>
              <img
                src='./img/info/img3.webp'
                alt=''
              />
            </div>

            <div className='info-block__descr'>
              <h2>Chirurgie</h2>
              <h3>Was sind Zahnimplantate?</h3>
              <p>
                Bei allen Eingriffen arbeiten wir mit einer minimalinvasiven
                Operationstechnik, mit der sich die knöchernen Kieferstrukturen
                bearbeiten lassen, ohne dass dabei benachbarte Nerven und
                Blutgefäße in Mitleidenschaft gezogen werden. Eine
                Zahnentfernung erfolgt meistens unter örtlicher Betäubung. Aber
                auch schwierigere Eingriffe unter Kurz- oder Vollnarkose sind in
                unseren modernen Praxisräumen und für unser Team kein Problem.
              </p>
            </div>
          </div>
          <div className='info-block'>
            <div className='info-block__img'>
              <img
                src='./img/info/img4.webp'
                alt=''
              />
            </div>

            <div className='info-block__descr'>
              <h2>Ästhetik</h2>
              <h3>
                Wahre Schönheit kommt von innen! Ein schönes Lachen macht
                attraktiv und gibt Ihnen eine positive Ausstrahlung. Zähne sind
                Ihre persönliche Visitenkarte!
              </h3>
              <p>
                Stark beschädigte Zähne werden mit einer Krone versorgt. Ein
                ästhetisch einwandfreies Ergebnis ermöglichen Keramikkronen. Sie
                sehen wie eigene Zähne aus und werden der Umgebung individuell
                angepasst. Sollte einmal ein Zahn verloren gehen, so besteht
                manchmal die ästhetische, immer aber die Funktion erhaltende
                Forderung nach vollwertigem Ersatz. Sind die Nachbarzähne ohne
                größere Füllungen, bietet sich eine implantatgetragene Lösung
                an. Sind jedoch die benachbarten Zähne ohnehin
                versorgungsbedürftig bzw. gibt es andere Gründe, die eine
                Implantation verhindern, so ist die festsitzende Brücke die
                richtige Entscheidung.
              </p>
            </div>
          </div>
          <div className='info-block'>
            <div className='info-block__img'>
              <img
                src='./img/info/img5.webp'
                alt=''
              />
            </div>

            <div className='info-block__descr'>
              <h2>Pardontologie</h2>
              <h3>Besser vorsorgen als Zahnsorgen.</h3>
              <p>
                Damit Sie auch morgen noch gut lachen haben, beschäftigen wir
                uns in der Parodontologie mit der Heilung und Gesunderhaltung
                des Zahnhalteapparates. Dazu gehören der Kieferknochen, das
                Zahnfleisch und die Fasern, die Zahn und Zahnfleisch bzw. Zahn
                und Knochen verbinden. Der Gesunderhaltung des Parodonts als
                Fundament unserer Zähne kommt damit eine sehr wichtige Bedeutung
                zu.
              </p>
            </div>
          </div>
          <div className='info-block'>
            <div className='info-block__img'>
              <img
                src='./img/info/img6.webp'
                alt=''
              />
            </div>

            <div className='info-block__descr'>
              <h2>Kieferorthopädie</h2>
              <h3>High Tech Zahnbehandlung</h3>
              <p>
                Sie sind trendy. Sie sind in. Und sie sind nicht nur in
                Teenie-Kreisen mehr als angesagt. Auch Stars von Tom Cruise bis
                Hermann Maier smilen mit Zahnspangen um die Wette.
              </p>
              <p>
                Es ist nie zu spät. Nach diesem Motto können Zahnfehlstellungen
                in jedem Alter behoben werden. Zur Korrektur von
                Kieferfehlstellungen wird bei Heranwachsenden die
                Funktionskieferorthopädie eingesetzt. Aber auch Erwachsenen, die
                unter einer falschen Kieferlage leiden, kann durch kombinierte
                kieferorthopädisch-kieferchirurgische Behandlungen geholfen
                werden.
              </p>
              <p>
                Zahnspangen können auch Folgeschäden vorbeugen, die durch Zahn-
                und Kieferfehlstellungen entstehen. Dies sind z. B. Erkrankungen
                der Zähne (Karies), des Zahnhalteapparates (Parodontitis) und
                der Kiefergelenke.
              </p>
            </div>
          </div>
          <div className='info-block'>
            <div className='info-block__img'>
              <img
                src='./img/info/img7.webp'
                alt=''
              />
            </div>

            <div className='info-block__descr'>
              <h2>Invisalign - Unsichtbare Zahnspangen</h2>
              <h3>
                Stört Sie Ihre Zahnstellung? Dann sind unsichtbare Zahnspangen
                vielleicht Ihre Lösung!
              </h3>
              <p>
                Zahn- und Gebissfehlstellungen zu korrigieren, ist ganz
                wesentlich für die Erhaltung der Gesundheit. Bei Fehlbissen
                zeigen sich schon manchmal bei über 20-jährigen erste Symptome
                wie Kopf- oder Nackenschmerzen, später sind es
                Kiefergelenksbeschwerden, Ohrsausen oder andere Beschwerden des
                Bewegungsapparates.
              </p>
              <p>
                Heutzutage ist es nicht mehr peinlich, sich mit 30+ mit einer
                Zahnregulierung auf die Straße zu trauen. Solange die
                Zahnsubstanz erhaltenswert ist, spricht in keinem Alter etwas
                dagegen, noch mit einer Regulierung anzufangen. 65 % aller
                Zahnfehlstellungen können mittels unsichtbaren und
                herausnehmbaren Zahnschienen behandelt werden. Die
                durchsichtigen Schienen stellen im Alltag fast keine
                Beeinträchtigung dar. Die Behandlung mit der diskreten Schiene
                wird in individuellen Phasen vorgenommen. Für jede
                Behandlungsphase wird eine neue Schiene angefertigt. Auf dem Weg
                zum perfekten Lächeln kommen rund 12 bis 40 Schienen zum
                Einsatz. Eine Zahnkorrektur ohne Metall und Drähte ist also
                möglich. Ebenso wie die klassische Zahnspange mit Metall- oder
                Keramikplättchen, den sogenannten Brackets, können auch diese
                transparenten Zahnschienen optimal zur Behandlung von
                zahlreichen Zahnfehlstellungen eingesetzt werden. Damit steht
                einem perfekten Aussehen mit strahlendem Lächeln und schönen
                Zähnen nichts mehr im Weg.
              </p>
            </div>
          </div>
          <div className='info-block'>
            <div className='info-block__img'>
              <img
                src='./img/info/img8.webp'
                alt=''
              />
            </div>

            <div className='info-block__descr'>
              <h2>Notdienst / Schmerzbehandlung</h2>
              <h3>
                Sie haben Zahnschmerzen oder einen Notfall? Bei uns können Sie
                sofort auch ohne Termin während unserer Öffnungszeiten
                reinkommen und werden aufgenommen.
              </h3>
              <p>
                Zahnschmerzen gehören zu den häufigsten Schmerzen. Da
                Zahnschmerzen meist nicht besser werden wenn man einfach
                abwartet, sondern sich eher noch verschlimmern, ist ein
                zeitnaher Zahnarztbesuch ratsam. Häufig ist es jedoch die Angst
                vor einer schmerzhaften Behandlung, die viele Menschen davon
                abhält den Weg zum Zahnarzt zu gehen. Die Schmerzbehandlung in
                der Zahnmedizin ist ein äußerst anspruchsvolles Fachgebiet.
                Jeder Zahnarzt sollte es beherrschen, denn gerade hier ist ein
                besonderes Fingerspitzengefühl und Einfühlungsvermögen neben
                Erfahrung und zahnmedizinischem Können gefragt. Ein Patient mit
                heftigen Zahnschmerzen ist in einer Stresssituation und er
                braucht Hilfe, nur der geschulte beruhigende Umgang durch das
                zahnmedizinische Fachpersonal und dem Zahnarzt kann die
                Stresssituation mildern und dadurch die anstehende notwendige
                Behandlung ermöglichen.
              </p>
            </div>
          </div>
          <div className='info-block'>
            <div className='info-block__img'>
              <img
                src='./img/info/img9.webp'
                alt=''
              />
            </div>

            <div className='info-block__descr'>
              <h2>Bleaching</h2>
              <h3>Blendende Aussichten</h3>
              <p>
                Gepflegte, weiße Zähne stärken das persönliche Wohlbefinden und
                heben die individuelle Attraktivität. Oberflächliche
                Verfärbungen durch Kaffee, Tee oder Tabak können durch die
                professionelle Mundhygiene entfernt werden. Tiefer liegende
                Verfärbungen erfordern die Verwendung spezieller Bleichmittel,
                welche die Zähne aufhellen. Voraussetzung dafür sind gesunde
                Zähne und ein gesundes Zahnfleisch. Fragen Sie uns nach der für
                Sie am besten geeigneten Methode!
              </p>
            </div>
          </div>
        </div>
      </section>
      <Form />
      <Footer />
    </>
  );
};

export default ServicesPages;
