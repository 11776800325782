import ContactForm from './ContactForm';
import './style.scss';

const Form = () => {
  return (
    <section
      className='form'
      id='form'>
      <div className='container'>
        <div className='form__img bottom-line'>
          <img
            src='./img/form/icon1.webp'
            alt=''
          />
        </div>
        <h2>
          <span>
            Nehmen Sie Kontakt zu Ihrem Zahnarzt des Vertrauens in Düsseldorf
            auf.
          </span>
        </h2>
        <p>
          Überfüllte Wartezimmer, unnötige Wartezeiten und Massenabfertigungen
          in Akkord werden Sie bei uns garantiert nicht vorfinden. Stattdessen
          legen wir großen Wert auf ausführliche Beratungsgespräche und
          persönliche Betreuung unserer Patienten. Denn nur wenn wir genau
          wissen, wo die Probleme des Kunden liegen, können wir auch effektiv
          und schmerzfrei dagegen vorgehen. Besuchen Sie unsere Zahnarztpraxis
          in Düsseldorf und überzeugen Sie sich selbst. Bei uns herrscht kein
          Gefühl der Angst, sondern familiäre Stimmung.
        </p>
        <p>
          Wir beweisen, dass ein Besuch beim Zahnarzt des Vertrauens auch Spaß
          und Wohlbefinden mit sich bringen kann. Die Zahnärzte der Fairdent
          Zahnarztpraxis in Düsseldorf sind voller Leidenschaft und Herz bei der
          Arbeit und haben ein großes Ziel: Gesunde und schöne Zähne für unsere
          Patienten und damit einhergehend ein neues und besseres Lebensgefühl.
        </p>

        <div className='form-contact'>
          <a href='tel:02111609977'>
            <img
              src='./img/form/phone.webp'
              alt=''
            />
            0211/1609977
          </a>
          <a href='mailto:fairdent.duesseldorf@outlook.de'>
            <img
              src='./img/form/mail.webp'
              alt=''
            />
            fairdent.duesseldorf@outlook.de
          </a>
        </div>
        <ContactForm />
      </div>
    </section>
  );
};

export default Form;
