import Form from '../Form/Form';
import Footer from '../Footer/Footer';
import './style.scss';
import { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Team = () => {
  useEffect(() => {
    gsap.to('.hero-img__descktop', {
      duration: 1.3,
      clipPath: 'inset(0% 0 0% 0)',
      scale: 1,
      ease: 'power2.inOut',
    });
    gsap.to('h1 span', {
      duration: 1.3,
      delay: 0.2,
      y: 0,
      ease: 'power4.inOut',
    });
    gsap.utils.toArray('h2 span').forEach((span) => {
      gsap.to(span, {
        scrollTrigger: {
          trigger: span,
          start: '-700% 30%',
          end: '0 0',
        },
        duration: 1.3,
        y: 0,
        ease: 'power4.inOut',
      });
    });

    gsap.utils.toArray('.team-block').forEach((span) => {
      gsap.to(span, {
        scrollTrigger: {
          trigger: span,
          start: '-60% 30%',
          end: '0 0',
        },
        onComplete() {
          span.classList.add('team-block-active');
        },
      });
    });
  }, []);
  return (
    <>
      <section className='team-page hero'>
        <div className='hero-img'>
          <img
            className='hero-img__descktop'
            src='./img/team/bg.jpg'
            alt=''
          />
        </div>
        <div className='container'>
          <div className='hero__wrapper'>
            {/* <div className='services-page-img bottom-line'>
              <img
                src='./img/guarantee/logo.webp'
                alt=''
              />
            </div> */}
            <h1 className='hero-title'>
              <span>Zahnarztpraxis Fairdent in Düsseldorf. Unser Team</span>
            </h1>
          </div>
        </div>
      </section>

      <section className='team'>
        <div className='container'>
          <h2>
            <span>Unser Team in Düsseldorf.</span>
          </h2>
          <div className='team__wrapper'>
            <div className='team-block'>
              <div className='team-block__wrapper'>
                <div className='team-block__img'>
                  <img
                    src='./img/team/img1.jpg'
                    alt=''
                  />
                </div>
                <div className='team-block__descr'>
                  <h3>
                    <span>Zahnarzt Idnan El Seid</span>
                  </h3>
                  <ul>
                    <li>
                      <span>Implantologie</span>
                    </li>
                    <li>
                      <span>Chirurgie</span>
                    </li>
                    <li>
                      <span>Zahnersatz</span>
                    </li>
                    <li>
                      <span>Ästhetik</span>
                    </li>
                    <li>
                      <span>Endodontie</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='team-block'>
              <div className='team-block__wrapper'>
                <div className='team-block__img'>
                  <img
                    src='./img/team/img1.jpg'
                    alt=''
                  />
                </div>
                <div className='team-block__descr'>
                  <h3>
                    <span>Zahnarzt Ahmad Husein</span>
                  </h3>
                  <ul>
                    <li>
                      <span>Implantologie</span>
                    </li>
                    <li>
                      <span>Chirurgie</span>
                    </li>
                    <li>
                      <span>Zahnersatz</span>
                    </li>
                    <li>
                      <span>Ästhetik</span>
                    </li>
                    <li>
                      <span>Endodontie</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='assistants team'>
        <div className='container'>
          <h2>
            <span>
              Unsere Mitarbeiter der Zahnarztpraxis Fairdent in Düsseldorf.
            </span>
          </h2>
          <div className='assistants__wrapper'>
            <div className='team-block assistants-block'>
              <div className='team-block__wrapper'>
                <div className='team-block__img'>
                  <img
                    src='./img/team/img3.jpg'
                    alt=''
                  />
                </div>
                <div className='team-block__descr'>
                  <h3>
                    <span>Christine Kroh</span>
                  </h3>
                  <ul>
                    <li>
                      <span>Abrechnung</span>
                    </li>
                    <li>
                      <span>Prophylaxe</span>
                    </li>
                    <li>
                      <span>Verwaltung</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='team-block assistants-block'>
              <div className='team-block__wrapper'>
                <div className='team-block__img'>
                  <img
                    src='./img/team/img3.jpg'
                    alt=''
                  />
                </div>
                <div className='team-block__descr'>
                  <h3>
                    <span>Hibet Errahmene Kadri</span>
                  </h3>
                  <ul>
                    <li>
                      <span>Auszubildende</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Form />
      <Footer />
    </>
  );
};

export default Team;
