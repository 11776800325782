import React from 'react';

const ScrollToForm = ({ children }) => {
  const handleScrollToForm = (e) => {
    console.log(e);
    e.preventDefault(); // Предотвращает стандартное поведение ссылки
    const formSection = document.querySelector('#form');

    if (formSection) {
      // Проверка, что элемент существует
      formSection.scrollIntoView({ behavior: 'smooth' }); // Плавный скролл
    }
  };

  return <div onClick={handleScrollToForm}>{children}</div>;
};

export default ScrollToForm;
