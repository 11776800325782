import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Form from '../Form/Form';
import ScrollToForm from '../ScrollToForm';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './style.scss';

gsap.registerPlugin(ScrollTrigger);

const MainPage = () => {
  document.querySelectorAll('a[href^="#"]').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      const id = el.getAttribute('href')?.slice(1);
      if (!id) return;
      const target = document.getElementById(id);
      if (target) {
        if (window.innerWidth > 768) {
          target.scrollIntoView({ behavior: 'smooth' });
        } else {
          target.scrollIntoView({ behavior: 'auto' });
        }
      }
    });
  });

  useEffect(() => {
    gsap.to('.hero-img__descktop', {
      duration: 1.3,
      clipPath: 'inset(0% 0 0% 0)',
      scale: 1.1,
      ease: 'power2.inOut',
    });
    gsap.to('h1 span', {
      duration: 1.3,
      delay: 0.2,
      y: 0,
      ease: 'power4.inOut',
    });
    gsap.utils.toArray('h2 span').forEach((span) => {
      gsap.to(span, {
        scrollTrigger: {
          trigger: span,
          start: '-700% 30%',
          end: '0 0',
        },
        duration: 1.3,
        y: 0,
        ease: 'power4.inOut',
      });
    });
    gsap.to('.hero-subtitle span', {
      duration: 1.3,
      delay: 0.3,
      y: 0,
      ease: 'power4.inOut',
    });
    gsap.to('.hero-btn', {
      duration: 1,
      delay: 0.1,
      opacity: 1,
      ease: 'power4.inOut',
    });
    gsap.to('.services-block', {
      scrollTrigger: {
        trigger: '.services-block',
        start: '-30% 50%',
        end: '0 0',
      },
      stagger: 0.2,
      duration: 1.3,
      opacity: 1,
      ease: 'power4.inOut',
    });

    gsap.utils.toArray('.advantages-block__img img').forEach((img) => {
      gsap.to(img, {
        scrollTrigger: {
          trigger: img,
          start: '-30% 30%',
          end: '0 0',
        },
        duration: 1.3,
        clipPath: 'inset(0% 0 0% 0)',
        scale: 1,
        ease: 'power2.inOut',
      });
    });

    gsap.utils.toArray('.advantages-block__descr').forEach((img) => {
      gsap.to(img, {
        scrollTrigger: {
          trigger: img,
          start: '-30% 30%',
          end: '0 0',
        },
        duration: 1.3,
        opacity: 1,
        ease: 'power2.inOut',
      });
    });

    setTimeout(() => {
      gsap.fromTo(
        '.guarantee-img',
        {
          scrollTrigger: {
            trigger: '.guarantee',
            scrub: true,
          },
          yPercent: -20,
          ease: 'none',
        },
        {
          scrollTrigger: {
            trigger: '.guarantee',
            scrub: true,
          },
          yPercent: 20,
          ease: 'none',
        }
      );
    }, 500);
  }, []);

  const imageRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [targetPosition, setTargetPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (window.innerWidth > 991) {
      const handleMouseMove = (e) => {
        const x = Math.floor(e.clientX * 0.02 - 20);
        const y = Math.floor(e.clientY * 0.02 - 20);

        setTargetPosition({ x, y });
      };

      window.addEventListener('mousemove', handleMouseMove);

      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, []);

  useEffect(() => {
    let animationFrame;
    const animate = () => {
      setPosition((prev) => ({
        x: prev.x + (targetPosition.x - prev.x) * 0.1,
        y: prev.y + (targetPosition.y - prev.y) * 0.1,
      }));

      if (imageRef.current) {
        imageRef.current.style.transform = `translate(${position.x}px,${position.y}px) scale(1.1)`;
      }

      animationFrame = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [targetPosition]);

  return (
    <>
      <section className='hero'>
        <div className='hero-img'>
          <img
            ref={imageRef}
            className='hero-img__descktop main-photo'
            src='./img/hero/hero-img-desktop.jpg'
            alt=''
          />
          {/* <img
            className='hero-img__mobile hero-img'
            src='./img/hero/hero-img.webp'
            alt=''
          /> */}
        </div>
        <div className='container'>
          <div className='hero__wrapper'>
            <h1 className='hero-title'>
              <span>Ihr Zahnarzt in Düsseldorf.</span>
            </h1>
            <h2 className='hero-subtitle bottom-line'>
              <span>
                Ihr Zahnarzt #1 in Düsseldorf und Umgebung Prophylaxe und
                Zahnersatz. Jeder fehlende Zahn senkt die Lebensqualität. Mit
                Fairdent zu schönen und gesunden Zähnen.
              </span>
            </h2>
            <ScrollToForm>
              <a
                href='#form'
                className='hero-btn button'>
                <span>Kontakt</span>
              </a>
            </ScrollToForm>
          </div>
        </div>
      </section>

      <section className='dentist'>
        <div className='container'>
          <div className='dentist__wrapper'>
            <div className='dentist-img bottom-line'>
              <img
                src='./img/icons/icon1.webp'
                alt=''
              />
            </div>
            <h2>
              <span>
                Ihr Zahnarzt in Düsseldorf für schöne und gesunde Zähne. Ohne
                Angst & Schmerzen.
              </span>
            </h2>
            <p>
              Als moderner <span>Zahnarzt in Düsseldorf</span> wollen wir
              eigentlich nur eines: die Gesundheit und Schönheit Ihrer Zähne in
              unsere Hände nehmen zu dürfen. Am liebsten ein Leben lang. Das
              funktioniert nur im Team und in einer Partnerschaft gemeinsam mit
              Ihnen als Patient. Wir nehmen Sie, Ihre Sorgen und Wünsche ernst.
              Kontaktieren Sie uns für eine schnelle Terminvergabe und Aufnahme
              in unsere Zahnarztpraxis in Düsseldorf. Wir legen hohen Wert auf
              den Schutz Ihrer Kundendaten und setzen Kundenzufriedenheit als
              oberste Priorität.
            </p>
            <p>
              Sie interessieren sich für die Bereiche Prophylaxe, Zahnersatz und
              Kieferorthopädie? Unsere Experten der Zahnmedizin nehmen die
              Wünsche Ihrer Patienten ernst. Hier ist der Patient, keine Nummer,
              sondern ein gern gesehener Gast, dem bei seinen Problemen geholfen
              wird.
            </p>
            <p>
              Wir freuen uns auf Ihren Besuch in unser Zahnarztpraxis Fairdent
              in Düsseldorf.
            </p>
            <ScrollToForm>
              <a
                href='#form'
                className='dentist-btn button'>
                <span>Kontakt</span>
              </a>
            </ScrollToForm>
          </div>
        </div>
      </section>

      <section className='services'>
        <div className='container'>
          <div className='services-img bottom-line'>
            <img
              src='./img/services/main-icon.webp'
              alt=''
            />
          </div>
          <h2>
            <span>Unsere Leistungen als Zahnarzt im Überblick.</span>
          </h2>

          <div className='services__wrapper'>
            <div className='services-block'>
              <div className='services-img bottom-line'>
                <img
                  src='./img/services/icon1.webp'
                  alt=''
                />
              </div>
              <h3>Prophylaktische Leistungen</h3>
              <ul className='services-block__list'>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Professionelle Zahnreinigung
                </li>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Kariesdiagnostik
                </li>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Beratung zu Ernährung/Mundhygiene
                </li>
              </ul>
            </div>
            <div className='services-block'>
              <div className='services-img bottom-line'>
                <img
                  src='./img/services/icon2.webp'
                  alt=''
                />
              </div>
              <h3>Zahnerhaltende Leistungen</h3>
              <ul className='services-block__list'>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Zahnfüllungen (amalgamfrei)
                </li>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  kosmetische Korrekturen
                </li>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Wurzelbehandlungen
                </li>
              </ul>
            </div>
            <div className='services-block'>
              <div className='services-img bottom-line'>
                <img
                  src='./img/services/icon3.webp'
                  alt=''
                />
              </div>
              <h3>Chirurgische Leistungen</h3>
              <ul className='services-block__list'>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Implantologie
                </li>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Zahnentfernung
                </li>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Wurzelspitzenresektionen
                </li>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Abszessbehandlung
                </li>
              </ul>
            </div>
            <div className='services-block'>
              <div className='services-img bottom-line'>
                <img
                  src='./img/services/icon4.webp'
                  alt=''
                />
              </div>
              <h3>Prothetische Leistungen</h3>
              <ul className='services-block__list'>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Festsitzender Zahnersatz
                </li>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  herausnehmbarer oder kombinierter Zahnersatz
                </li>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Zahnersatzreparatur
                </li>
                <li>
                  <img
                    src='./img/services/check.webp'
                    alt=''
                  />
                  Implantatprothetik
                </li>
              </ul>
            </div>
          </div>
          <NavLink
            to='/leistungen'
            className='services-btn button'>
            <span>Alle Zahnarzt-Leistungen</span>
          </NavLink>
        </div>
      </section>

      <section className='guarantee'>
        <img
          className='guarantee-img'
          src='./img/guarantee/bg.webp'
          alt=''
        />
        <div className='container'>
          {/* <div className='guarantee-text-img bottom-line'>
            <img
              src='./img/guarantee/logo.webp'
              alt=''
            />
          </div> */}
          <h2>
            <span>Der Zahnarzt in Düsseldorf mit Zufriendenheitsgarantie.</span>
          </h2>
          <p>
            Die Zufriedenheit der Patienten steht bei uns an erster Stelle. Um
            Sie zu verstehen bieten wir einen unverbindlichen Beratungstermin in
            einer unserer Praxen an. Sie erhalten nach jeder Beratung einen
            ausführlichen und verständlichen Brief mit allen Informationen. Gern
            schicken wir diesen auf Wunsch auch direkt während des Termins an
            Ihren Hauszahnarzt. Sollten Sie vor während oder nach der Behandlung
            einmal Wünsche oder Kritik haben sind sowohl unsere Ärzte als auch
            unsere Feel-Good-Managerin immer persönlich für Sie da.
          </p>
          <ScrollToForm>
            <a
              href='#form'
              className='guarantee-btn button'>
              <span>Kontakt</span>
            </a>
          </ScrollToForm>
        </div>
      </section>

      <section className='advantages'>
        <div className='advantages__wrapper'>
          <div className='advantages-block'>
            <div className='advantages-block__img'>
              <img
                src='./img/advantages/img1.webp'
                alt=''
              />
            </div>
            <div className='advantages-block__descr'>
              <div className='advantages-block__descr_img bottom-line'>
                <img
                  src='./img/advantages/icon1.webp'
                  alt=''
                />
              </div>
              <h3>Hier gehen Kinder noch gerne in die Zahnarztpraxis.</h3>
              <p>
                Wer im Alter noch schöne und gesunde Zähne haben will, sollte
                schon frühstmöglich damit anfangen. Daher ist es schon für Ihre
                Kleinsten wichtig, regelmäßig zum Zahnarzt in Düsseldorf zu
                gehen. Dadurch lassen sich Fehlstellungen der Zähne oder falsche
                Zähneputz-Angewohnheiten vermeiden. Wir möchten natürlich, dass
                auch die Ihre Kleinsten keinerlei Angst vorm Zahnarzt haben.
                Fairdent hat sich auch dies als Ziel gesetzt. Die Kinder sind
                unsere Zukunft von morgen und so sollten wir sie auch behandeln.
                Kinder sollen sich in unserer Kinder-Zahnarztpraxis in
                Düsseldorf wohl fühlen, Spaß haben und auch gerne zurück kommen.
              </p>
              <p>
                Daher haben wir extra für unsere jüngsten Patienten eine eigene
                Abteilung Kinder-Zahnarztpraxis in Düsseldorf aufgebaut. Hier
                wird die Wartezeit garantiert nicht langweilig und sorgt zudem
                für strahlende Gesichter in unserer Zahnarztpraxis. Ein Ort zum
                Austoben, Spielen und Geschichten lesen.. Ein Ort zum Kind sein,
                zum Malen und Zeichnen. Hier haben Kinder ihre eigene Welt, in
                der sie in der Wartezeit einfach sie selbst sein dürfen.
              </p>
              <p>Ihre Zahnarztpraxis für klein und groß, jung und alt.</p>
              <ScrollToForm>
                <a
                  href='#form'
                  className='advantages-btn button'>
                  <span>Kontakt</span>
                </a>
              </ScrollToForm>
            </div>
          </div>
          <div className='advantages-block'>
            <div className='advantages-block__img'>
              <img
                src='./img/advantages/img2.webp'
                alt=''
              />
            </div>

            <div className='advantages-block__descr'>
              <div className='advantages-block__descr_img bottom-line'>
                <img
                  src='./img/advantages/icon2.webp'
                  alt=''
                />
              </div>
              <h3>Kompetente und individuelle Zahnbehandlungen</h3>
              <p>
                Das oberste Ziel der Zahnarztpraxis Fairdent in Düsseldorf ist
                es, Sie mit professioneller und modernster Zahnmedizin
                individuell und ausführlich zu versorgen – ganz egal ob es um
                den Erhalt oder die Wiederherstellung Ihrer Zahngesundheit geht.
                Deshalb steht am Anfang jeder unserer Zahnbehandlungen eine
                ausführliche Anamnese und Diagnose Ihrer jeweiligen Zahn- und
                Zahnfleischleiden. Unsere Leistungsspektren umfassen dabei
                sowohl die ästhetische und prophylaktische Zahnmedizin und
                Behandlung, durch professionelle Zahnreinigung, Bleaching und
                Air Flow, als auch medizinische Zahnbehandlungen im Bereich der
                Parodontologie, der Endodontie und des Zahnersatzes.
              </p>
              <p>
                Das Handeln unserer Zahnärzte wird dabei stets von Ihrem
                Wohlbefinden und Gefühlbestimmt. Da es uns – neben der
                kompetenten, modernen und schonenden Zahnbehandlung – besonders
                am Herzen liegt, Sie in einem ruhigen und familiären Umfeld zu
                versorgen, haben wir unsere Zahnarztpraxis so angenehm wie
                möglich gestaltet. Dies merken vor allem auch unsere jungen und
                kleinen Patientinnen und Patienten, auf deren Behandlung wir uns
                als Zahnarztpraxis unter anderem spezialisiert haben.
              </p>
              <ScrollToForm>
                <a
                  href='#form'
                  className='advantages-btn button'>
                  <span>Kontakt</span>
                </a>
              </ScrollToForm>
            </div>
          </div>
          <div className='advantages-block'>
            <div className='advantages-block__img'>
              <img
                src='./img/advantages/img3.webp'
                alt=''
              />
            </div>

            <div className='advantages-block__descr'>
              <div className='advantages-block__descr_img bottom-line'>
                <img
                  src='./img/advantages/icon3.webp'
                  alt=''
                />
              </div>
              <h3>Zahnarztpraxis Fairdent: Unsere Philosophie</h3>
              <p>
                Im Mittelpunkt unserer Tätigkeit als Zahnarzt in Düsseldorf
                steht der Patient als Mensch. Wir suchen immer das Gespräch mit
                unseren Patienten, um ihre Probleme, Wünsche und Ziele zu
                erfahren und dann ein hohes Maß an Lebensqualität und
                Wohlbefinden in unserem Fachgebiet der Zahnmedizin zu geben.
              </p>
              <p>
                Die Aufklärung des Patienten umfasst viele verschiedene
                Bereiche: Beispielsweise die Entstehung von Erkrankungen, die
                passende Therapie und andere Alternativen, eine kompetente
                Prognose und Nachsorge.
              </p>
              <p>
                Der Behandlungsschwerpunkt liegt bei Fairdent auf der
                Prophylaxe, Ästhetik und Funktion Ihrer Zähne.
              </p>
              <p>
                Gesunde und schöne Zähne zu besitzen war nie einfacher als mit
                Fairdent in Düsseldorf. Ihre Zahnarztpraxis in Düsseldorf mit
                Zufriedenheitsgarantie.
              </p>
              <ScrollToForm>
                <a
                  href='#form'
                  className='advantages-btn button'>
                  <span>Kontakt</span>
                </a>
              </ScrollToForm>
            </div>
          </div>
        </div>
      </section>
      <Form />
      <Footer />
    </>
  );
};

export default MainPage;
