import { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ScrollToForm from '../ScrollToForm';

import './style.scss';

const Header = () => {
  const [isMenuActive, setMenuActive] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const headerMenuRef = useRef(null); // Реф для хранения ссылки на меню

  const handleBurgerClick = () => {
    setMenuActive(!isMenuActive);
  };

  const handleMenuClick = () => {
    setMenuActive(false); // Удаляем класс 'menu-active' по клику на любой пункт меню
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const scrollDirection = scrollPosition > lastScrollY ? 'down' : 'up';

        if (scrollDirection === 'down' && scrollPosition > 100) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }

        setLastScrollY(scrollPosition);

        const sections = document.querySelectorAll('section[id]');
        sections.forEach((section) => {
          const sectionTop = section.offsetTop;
          const sectionHeight = section.offsetHeight;

          if (
            scrollPosition >= sectionTop &&
            scrollPosition < sectionTop + sectionHeight
          ) {
            setActiveLink(`#${section.id}`);
          }
        });
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [lastScrollY]);

  return (
    <>
      <header className={`header ${isVisible ? '' : 'header-hidden'}`}>
        <div className='container'>
          <div className='header__wrapper'>
            <NavLink
              className='header-logo'
              to='.'>
              <img
                src='../img/logo/logo.webp'
                alt='Logo'
              />
            </NavLink>
            {/* <a
              href='/'
              className='header-logo'>
              <img
                src='../img/logo/logo.webp'
                alt='Logo'
              />
            </a> */}
            <nav
              ref={headerMenuRef}
              className={`header-menu ${isMenuActive ? 'menu-active' : ''}`}>
              <ul>
                <li>
                  <NavLink
                    to='/'
                    onClick={handleMenuClick}>
                    Startseite
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='leistungen'
                    onClick={handleMenuClick}>
                    Leistungen
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='standorte'
                    onClick={handleMenuClick}>
                    Standorte
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='team'
                    onClick={handleMenuClick}>
                    Team
                  </NavLink>
                </li>
              </ul>
            </nav>
            <div className='header-buttons'>
              {/* <a
                href='#'
                className='header-buttons__smile button'>
                <span>GermanySmiles</span>
              </a> */}
              <ScrollToForm>
                <a
                  href='#form'
                  className='header-buttons__contact button'>
                  <span>Kontakt</span>
                </a>
              </ScrollToForm>
            </div>
            <div
              className={`burger ${isMenuActive ? 'burger-active' : ''}`}
              onClick={handleBurgerClick}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
