import {
  HashRouter as BrowserRouter,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import MainPage from './MainPage/MainPage';
import ServicesPages from './ServicesPage/ServicesPage';
import Locations from './Locations/Locations';
import NotFound from './NotFound/NotFound';
import Impressum from './Impressum/Impressum';
import Team from './Team/Team';
import DataProtection from './DataProtection/DataProtection';
import Layout from './layouts/Layout';

const AnimatedRoutes = () => {
  const location = useLocation();
  const [isScrolling, setIsScrolling] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    if (isScrolling) {
      const timer = setTimeout(() => {
        window.scrollTo({ top: 0 });
        setIsScrolling(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isScrolling]);

  useEffect(() => {
    setIsScrolling(true);
  }, [pathname]);

  return (
    <AnimatePresence mode='wait'>
      <Routes
        location={location}
        key={location.pathname}>
        <Route
          path='/'
          element={<Layout />}
        />
        <Route
          index
          element={
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}>
              <MainPage />
            </motion.div>
          }
        />
        <Route
          path='/leistungen'
          element={
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}>
              <ServicesPages />
            </motion.div>
          }
        />
        <Route
          path='/standorte'
          element={
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}>
              <Locations />
            </motion.div>
          }
        />
        <Route
          path='/impressum'
          element={
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}>
              <Impressum />
            </motion.div>
          }
        />
        <Route
          path='/datenschutzerklärung'
          element={
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}>
              <DataProtection />
            </motion.div>
          }
        />
        <Route
          path='/team'
          element={
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}>
              <Team />
            </motion.div>
          }
        />
        <Route
          path='*'
          element={
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}>
              <NotFound />
            </motion.div>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
