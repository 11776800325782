import { NavLink } from 'react-router-dom';
import './style.scss';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <h2>
          <span>Sie haben Fragen?</span>
        </h2>
        <div className='footer-info'>
          <div className='footer-block'>
            <h3>Öffnungszeiten:</h3>
            <h4>Montag - Freitag</h4>
            <p>8:00 – 20:00 Uhr</p>
            {/* <p>15:00 – 18:30 Uhr</p> */}
          </div>
          <div className='footer-block'>
            <h3>Adresse:</h3>
            <p>
              Fairdent Zahnarztpraxis Düsseldorf Worringer Platz 18, 40210
              Düsseldorf
            </p>
          </div>
          {/* <div className='footer-block'>
            <h4>Mittwoch</h4>
            <p>8:00 – 13:00 Uhr</p>
            <p>15:00 – 18:00 Uhr</p>
          </div> */}
          <div className='footer-block'>
            <h4>Kontakt:</h4>
            <a href='mailto:fairdent.duesseldorf@outlook.de'>
              fairdent.duesseldorf@outlook.de
            </a>
            <a href='tel:02111609977'>0211/1609977</a>
          </div>
        </div>
        <div className='footer-cookies'>
          <NavLink to='/impressum'>Impressum</NavLink>
          <NavLink to='/datenschutzerklärung'>Datenschutzerklärung</NavLink>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
