import './style.scss';

const NotFound = () => {
  return (
    <section className='not-found'>
      <h2>404</h2>
      <p>Page not found</p>
    </section>
  );
};

export default NotFound;
