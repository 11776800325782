import { useState } from 'react';
import './style.scss';

const FORM_ENDPOINT =
  'https://public.herotofu.com/v1/ba4af5a0-7521-11ef-b2f9-6b1c6cf0ee54'; // Обновленный endpoint

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Form response was not ok');
        }

        setSubmitted(true);
      })
      .catch((err) => {
        e.target.submit();
      });
  };

  if (submitted) {
    return (
      <div className='form-succes'>
        Ihre Nachricht wurde erfolgreich gesendet. Wir werden uns in Kürze mit
        Ihnen in Verbindung setzen.
      </div>
    );
  }

  return (
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method='POST'
      className='form-fields'>
      <div className='form-fields-wrap'>
        <div className='form-fields-field'>
          <input
            type='text'
            placeholder='Vorname'
            name='name'
            className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none'
            required
          />
        </div>
        <div className='form-fields-field'>
          <input
            type='text'
            placeholder='Nachname'
            name='lastName'
            className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none'
            required
          />
        </div>
      </div>
      <div className='form-fields-wrap'>
        <div className='form-fields-field'>
          <input
            type='email'
            placeholder='E-Mail-Adresse'
            name='email'
            className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none'
            required
          />
        </div>
        <div className='form-fields-field'>
          <input
            type='tel'
            placeholder='Telefon'
            name='phone'
            className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none'
            required
          />
        </div>
      </div>
      <div className='form-fields-field'>
        <textarea
          placeholder='Geben Sie hier Ihre Nachricht ein'
          name='message'
          className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none'
          required
        />
      </div>
      <button
        className='button'
        type='submit'>
        <span className='button-text'>Einreichen</span>
      </button>
    </form>
  );
};

export default ContactForm;
