import { React } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactLenis } from '@studio-freight/react-lenis';
import Header from './components/Header/Header';
import AnimatedRoutes from './components/AnimationRoutes';
import PhoneFixed from './components/PhoneFixed';

function App() {
  return (
    <ReactLenis root>
      <div className='App'>
        <BrowserRouter>
          <Header />
          <PhoneFixed />
          <AnimatedRoutes />
        </BrowserRouter>
      </div>
    </ReactLenis>
  );
}

export default App;
