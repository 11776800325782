import Form from '../Form/Form';
import Footer from '../Footer/Footer';
import ScrollToForm from '../ScrollToForm';
import './style.scss';
import { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Swiper from 'swiper';
import 'swiper/css';

gsap.registerPlugin(ScrollTrigger);

const multiplier = {
  translate: 0.1,
  rotate: window.innerWidth < 600 ? 0.02 : 0.01,
};

const imagePaths = [
  'img/locations/img1.jpg',
  'img/locations/img2.jpg',
  'img/locations/img3.jpg',
  'img/locations/img4.jpg',
  'img/locations/img5.jpg',
  'img/locations/img6.jpg',
];

const Locations = () => {
  useEffect(() => {
    gsap.to('.hero-img__descktop', {
      duration: 1.3,
      clipPath: 'inset(0% 0 0% 0)',
      scale: 1,
      ease: 'power2.inOut',
    });
    gsap.to('h1 span', {
      duration: 1.3,
      delay: 0.2,
      y: 0,
      ease: 'power4.inOut',
    });
    gsap.utils.toArray('h2 span').forEach((span) => {
      gsap.to(span, {
        scrollTrigger: {
          trigger: span,
          start: '-700% 30%',
          end: '0 0',
        },
        duration: 1.3,
        y: 0,
        ease: 'power4.inOut',
      });
    });
    gsap.to('.address-block', {
      scrollTrigger: {
        trigger: '.address-block',
        start: '-30% 50%',
        end: '0 0',
      },
      stagger: 0.2,
      duration: 1.3,
      opacity: 1,
      ease: 'power4.inOut',
    });
  }, []);

  useEffect(() => {
    const swiper = new Swiper('.swiper', {
      slidesPerView: 'auto',
      spaceBetween: window.innerWidth > 991 ? 90 : 40,
      centeredSlides: true,
      loop: true,
      grabCursor: true,
    });

    function calculateWheel() {
      const slides = document.querySelectorAll('.single');
      slides.forEach((slide) => {
        const rect = slide.getBoundingClientRect();
        const r = window.innerWidth * 0.5 - (rect.x + rect.width * 0.5);
        let ty =
          Math.abs(r) * multiplier.translate -
          rect.width * multiplier.translate;

        if (ty < 0) {
          ty = 0;
        }
        const transformOrigin = r < 0 ? 'left top' : 'right top';
        slide.style.transform = `translate(0, ${ty}px) rotate(${
          -r * multiplier.rotate
        }deg)`;
        slide.style.transformOrigin = transformOrigin;
      });
    }

    function raf() {
      requestAnimationFrame(raf);
      calculateWheel();
    }

    raf();
  }, []);

  return (
    <>
      <section className='locations-page hero'>
        <div className='hero-img'>
          <img
            className='hero-img__descktop'
            src='./img/locations/bg.jpg'
            alt=''
          />
          {/* <img
            className='hero-img__mobile hero-img'
            src='./img/hero/hero-img.webp'
            alt=''
          /> */}
        </div>
        <div className='container'>
          <div className='hero__wrapper'>
            {/* <div className='services-page-img bottom-line'>
              <img
                src='./img/guarantee/logo.webp'
                alt=''
              />
            </div> */}
            <h1 className='hero-title'>
              <span>Zahnarzt in Düsseldorf. Standorte</span>
            </h1>
          </div>
        </div>
      </section>

      <section className='dentist'>
        <div className='container'>
          <div className='dentist__wrapper'>
            <div className='dentist-img bottom-line'>
              <img
                src='./img/icons/icon1.webp'
                alt=''
              />
            </div>
            <h2>
              <span>
                Ihr Zahnarzt in Düsseldorf für schöne und gesunde Zähne. Ohne
                Angst & Schmerzen.
              </span>
            </h2>
            <p>
              Als moderner <span>Zahnarzt in Düsseldorf</span> wollen wir
              eigentlich nur eines: die Gesundheit und Schönheit Ihrer Zähne in
              unsere Hände nehmen zu dürfen. Am liebsten ein Leben lang. Das
              funktioniert nur im Team und in einer Partnerschaft gemeinsam mit
              Ihnen als Patient. Wir nehmen Sie, Ihre Sorgen und Wünsche ernst.
              Kontaktieren Sie uns für eine schnelle Terminvergabe und Aufnahme
              in unsere Zahnarztpraxis in Düsseldorf. Wir legen hohen Wert auf
              den Schutz Ihrer Kundendaten und setzen Kundenzufriedenheit als
              oberste Priorität.
            </p>
            <p>
              Sie interessieren sich für die Bereiche Prophylaxe, Zahnersatz und
              Kieferorthopädie? Unsere Experten der Zahnmedizin nehmen die
              Wünsche Ihrer Patienten ernst. Hier ist der Patient, keine Nummer,
              sondern ein gern gesehener Gast, dem bei seinen Problemen geholfen
              wird.
            </p>
            <p>
              Wir freuen uns auf Ihren Besuch in unser Zahnarztpraxis Fairdent
              in Düsseldorf.
            </p>
            <ScrollToForm>
              <a
                href='#form'
                className='dentist-btn button'>
                <span>Kontakt</span>
              </a>
            </ScrollToForm>
          </div>
        </div>
      </section>

      <section className='address'>
        <div className='container'>
          <div className='address-img bottom-line'>
            <img
              src='./img/locations/icon1.webp'
              alt=''
            />
          </div>
          <h2>
            <span>Die Zahnarztpraxen von FairDent im Überblick</span>
          </h2>

          <div className='address__wrapper'>
            <div className='address-block'>
              <img
                src='./img/locations/map.webp'
                alt=''
              />
              <h3>FairDent Düsseldorf</h3>
              <h4>Adresse:</h4>
              <a
                href='https://www.google.com/maps/place/Worringer+Pl.+18,+40210+D%C3%BCsseldorf/@51.2241719,6.7922603,17z/data=!3m1!4b1!4m6!3m5!1s0x47b8ca2e3d59ca27:0x499aeb56e753d66e!8m2!3d51.2241686!4d6.7948352!16s%2Fg%2F11c25m5msv?hl=ru&entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D'
                target='_blank'>
                Worringer Platz 18, 40210 Düsseldorf
              </a>
            </div>
            <div className='address-block'>
              <img
                src='./img/locations/map.webp'
                alt=''
              />
              <h3>FairDent Münster</h3>
              <h4>Adresse:</h4>
              <a
                href='https://www.google.com/maps/place/Sprickmannpl.+2,+48159+M%C3%BCnster/@51.9988054,7.5922476,17z/data=!3m1!4b1!4m6!3m5!1s0x47b9b083269fd995:0xcc09648aafc2107!8m2!3d51.9988021!4d7.5948225!16s%2Fg%2F11tjhbw6vv?hl=ru&entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D'
                target='_blank'>
                Sprickmannsplatz 2 48159 Münster
              </a>
            </div>
            <div className='address-block'>
              <img
                src='./img/locations/map.webp'
                alt=''
              />
              <h3>FairDent Gelsenkirchen</h3>
              <h4>Adresse:</h4>
              <a
                href='https://www.google.com/maps/place/Zahnarztpraxis+FairDent+Gelsenkirchen/@51.503434,7.1034612,16z/data=!3m1!5s0x47b8e71ba8bc498f:0x596aec4f4f2468fa!4m15!1m8!3m7!1s0x47b8e71ba8bb5651:0x7a4d1f5b12bc094c!2sBochumer+Stra%C3%9Fe+22,+45879+Gelsenkirchen!3b1!8m2!3d51.503434!4d7.1034612!16s%2Fg%2F11c5mfyd9m!3m5!1s0x47b8e746f01878c1:0x98d735967a6030af!8m2!3d51.5034202!4d7.1034899!16s%2Fg%2F11sfbx1mgv?hl=ru&entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D'
                target='_blank'>
                Bochumerstr.22, 45879 Gelsenkirchen
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='cabinet'>
        <div className='container'>
          <div className='cabinet-img bottom-line'>
            <img
              src='./img/locations/icon2.webp'
              alt=''
            />
          </div>
          <h2>
            <span>Eindrücke unserer Zahnarztpraxis in Düsseldorf.</span>
          </h2>
        </div>

        <div className='carousel'>
          <div className='swiper'>
            <div className='swiper-wrapper'>
              {imagePaths.map((path, index) => (
                <div
                  className='swiper-slide'
                  key={index}>
                  <div className='single'>
                    <img
                      src={path}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Form />
      <Footer />
    </>
  );
};

export default Locations;
